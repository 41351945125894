import React, { useEffect } from "react";
import { TennisProvider, useTennisContext } from "./TennisContext";
import TennisController from "./TennisController";
import TennisScoreboard from "./TennisScoreboard";
import PropTypes from "prop-types";
import { isMonitor } from "../../../contexts/ScoreboardContext";

const PadelWrapper = ({ isController }) => {
  return (
    <TennisProvider>
      <Padel isController={isController} />
    </TennisProvider>
  );
};

const Padel = ({ isController }) => {
  const {
    tennisVariant,
    setTennisVariant,
    setCurrentScore,
    setFirstToGames,
    setTiebreakLegs,
    updateFB,
  } = useTennisContext();

  useEffect(() => {
    setTennisVariant("3");
    setFirstToGames(6);
    setTiebreakLegs(3);
    setCurrentScore([
      { player1: 0, player2: 0 },
      { player1: 0, player2: 0 },
    ]);

    updateFB({
      tennisVariant: "3",
      firstToGames: 6,
      currentScore: [
        { player1: 0, player2: 0 },
        { player1: 0, player2: 0 },
      ],
    });
  }, [tennisVariant, isController]);

  return (
    <>
      {isMonitor ? null : <TennisController isController={isController} />}
      <TennisScoreboard isController={isController} />
    </>
  );
};

PadelWrapper.propTypes = {
  isController: PropTypes.bool,
};

Padel.propTypes = {
  isController: PropTypes.bool,
};

PadelWrapper.defaultProps = {
  isController: false,
};

export default PadelWrapper;
