import React, { useMemo } from "react";
import PropTypes from "prop-types";

import {
  isMonitor,
  useScoreboardContext,
} from "../../../contexts/ScoreboardContext";
import { sportsSettings } from "../Initialise_Settings";
import { useTennisContext } from "./TennisContext";

const Sb = ({ Scoreboard }) => <Scoreboard />;

Sb.propTypes = {
  Scoreboard: PropTypes.any,
};

const TennisScoreboard = ({ isController }) => {
  const { currentScoreboard } = useScoreboardContext();
  const { tennisVariant } = useTennisContext();

  const scoreboardVariable = useMemo(() => {
    return tennisVariant === "2"
      ? sportsSettings.pb.scoreboards[currentScoreboard].sb
      : tennisVariant === "1"
      ? sportsSettings.pp.scoreboards[currentScoreboard].sb
      : tennisVariant === "3"
      ? sportsSettings.pd.scoreboards[currentScoreboard].sb
      : sportsSettings.t.scoreboards[currentScoreboard].sb;
  }, [tennisVariant, currentScoreboard]);

  let style = isMonitor
    ? {
        transform: "scale(1)",
        transformOrigin: "center",
      }
    : {
        width: isController ? "95%" : tennisVariant === "1" ? 600 : 700,
        maxWidth: 700,
        marginLeft: isController ? "auto" : 0,
        marginRight: "auto",
      };

  return (
    <div style={style}>
      <Sb Scoreboard={scoreboardVariable} />
    </div>
  );
};

TennisScoreboard.propTypes = {
  isController: PropTypes.bool,
};

export default TennisScoreboard;
