/* eslint-disable */
import React, { useContext, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAuth } from "./AuthContext";
import { get, getDatabase, onValue, ref, set, update } from "firebase/database";
import useTranslations from "./useTranslations";
import { logEvent } from "firebase/analytics";
import { analytics, db } from "../firebase";
import {
  priceToSportsDictionary,
  sportsDictionary,
  sportsSettings,
} from "../scoreboard-app/components/Initialise_Settings";
import { useSubscription } from "./SubscriptionContext";
import { getCookie, setCookie } from "../functions/app_functions";
import { doc, setDoc } from "firebase/firestore";
import { useLocation } from "react-router-dom";

let bcast;

const ScoreboardContext = React.createContext();
try {
  bcast = new BroadcastChannel("app-data");
} catch (error) {
  bcast = null;
}

export function useScoreboardContext() {
  return useContext(ScoreboardContext);
}

export const isMonitor = new URL(window.location.href).pathname.includes(
  "/monitor"
);

export function ScoreboardProvider(props) {
  const t = useTranslations();
  const { currentUser } = useAuth();
  const { subscriptionPriceId } = useSubscription();
  const db = getDatabase();
  const location = useLocation();

  // State hooks for controlling various aspects of the application
  const [started, setStarted] = useState(false);
  const [enableFB, setEnableFB] = useState(false);
  const [enableListener, setEnableListener] = useState(false);
  const [isPhoneController, setIsPhoneController] = useState(false);
  const [isRugbyLeague, setIsRugbyLeague] = useState(false);
  const [loading, setLoading] = useState(true);
  const [parameter1, setParameter1] = useState(false); // e.g. isWomen's

  // State hooks for managing sports-related data
  const [currentSport, setCurrentSport] = useState("f");
  const [teamA, setTeamA] = useState(t("noun:team") + " A");
  const [teamB, setTeamB] = useState(t("noun:team") + " B");
  const [currentScore, setCurrentScore] = useState([0, 0, 0, 0]);
  const [currentScoreboard, setCurrentScoreboard] = useState(0);
  const [halfLength, setHalfLength] = useState(30);
  const [sportsInc, setSportsInc] = useState([]);
  const [tennisVariant, setTennisVariant] = useState("0");
  const [crest, setCrest] = useState(["", ""]);
  const [links, setLinks] = useState({ monitorLink: "", controllerLink: "" });

  // State hooks for managing time-related data
  const [startTime, setStartTime] = useState(0);
  const [firstHalfCounter, setFirstHalfCounter] = useState(0);
  const [secondHalfCounter, setSecondHalfCounter] = useState(0);
  const [firstHalfCompleted, setFirstHalfCompleted] = useState(false);
  const [secondHalfCompleted, setSecondHalfCompleted] = useState(false);
  const [timeIsRed, setTimeIsRed] = useState(false);
  const [time, setTime] = useState("00:00");
  const [timeInc, setTimeInc] = useState(-1);
  const [timeFB, setTimeFB] = useState(-1);
  const [timeForward, setTimeForward] = useState(true);
  const [addedTime, setAddedTime] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);

  // State hooks for managing colour and visual data
  const [colors, setColors] = useState({
    c1: "#fff",
    c2: "#fff",
    c3: "#fff",
    c4: "#fff",
    color: { r: 0, g: 0, b: 0 },
    currentColor: "",
    sbBGColor: "",
  });
  const [showColorPicker, setShowColorPicker] = useState(false);

  // State hooks for managing game data
  const [cards, setCards] = useState(["", ""]);
  const [cardsObj, setCardsObj] = useState([
    {
      black: 0,
      yellow: 0,
      red: 0,
    },
    {
      black: 0,
      yellow: 0,
      red: 0,
    },
  ]);

  // State hooks for managing modal visibility
  const [showModal, setShowModal] = useState({
    alreadyLoggedIn: false,
    continue: false,
    changeTime: false,
    settings: false,
    addedTime: false,
    instructions: false,
    getLinks: false,
  });

  // State hooks for other miscellaneous data
  const [mouse, setMouse] = useState([0, 0]);
  const [generatedCode, setGeneratedCode] = useState(t("noun:generate-code"));
  const [displayCode, setDisplayCode] = useState(t("noun:generate-code"));
  const [mode, setMode] = useState("topLeft");
  const [invalidCode, setInvalidCode] = useState();
  const [showTotalScore, setShowTotalScore] = useState(false);
  const [isSpaceEnabled, setIsSpaceEnabled] = useState(false);
  const [data, setData] = useState({});
  const [modalCrestsState, setModalCrestsState] = useState(0);

  const updateFB = (updateObject) => {
    if (enableFB && !isMonitor) {
      const monitorsRef = ref(db, `monitors/${generatedCode}`); // Create a reference to the Realtime DB path
      update(monitorsRef, updateObject); // Set the data in the Realtime DB
      console.log({ generatedCode, updateObject });
      const userRef = ref(db, `monitors/${generatedCode}`);
      update(userRef, updateObject);
    }
  };

  // set default sport at start
  useEffect(() => {
    let firstSport = priceToSportsDictionary?.[subscriptionPriceId];
    let cookie = getCookie("selectedSport");
    if (cookie) {
      firstSport = cookie;
    }

    setSportsInc(["f", "s", "r", "d", "t", "p", "bb", "pd"]);

    if (firstSport) {
      setCurrentSport(firstSport);
      setTeamA(sportsSettings[firstSport].playerA);
      setTeamB(sportsSettings[firstSport].playerB);
      setLoading(false);
    }
  }, [subscriptionPriceId]);

  useEffect(() => {
    if (
      (teamA === "Player A" || teamA === "Team A") &&
      (teamB === "Player B" || teamB === "Team B")
    ) {
      setTeamA(sportsSettings[currentSport].playerA);
      setTeamB(sportsSettings[currentSport].playerB);
    }
    setCookie("selectedSport", currentSport);
  }, [currentSport]);

  useEffect(() => {
    if (timeInc >= 0) {
      setMinutes(timeFB);
      setSeconds(0);
      setTimeIsRed(false);
    }
  }, [timeInc, timeFB]);

  useEffect(() => {
    if (!showColorPicker) updateFB({ colors });
  }, [colors, showColorPicker]);

  useEffect(() => {
    if (firstHalfCounter === 5) {
      setFirstHalfCompleted(true);
      logEvent(analytics, "first_half_game_complete", {
        sport: sportsDictionary?.[currentSport] || "",
        scoreboard: currentScoreboard,
      });
    }
  }, [firstHalfCounter]);

  useEffect(() => {
    if (secondHalfCounter === 5) {
      setSecondHalfCompleted(true);
      logEvent(analytics, "second_half_game_complete", {
        sport: sportsDictionary?.[currentSport] || "",
        scoreboard: currentScoreboard,
      });
    }
  }, [secondHalfCounter]);

  useEffect(() => {
    if (firstHalfCompleted && secondHalfCompleted) {
      logEvent(analytics, "full_game_complete", {
        sport: sportsDictionary?.[currentSport] || "",
        scoreboard: currentScoreboard,
      });
    }
  }, [firstHalfCompleted, secondHalfCompleted]);

  useEffect(() => {
    if (generatedCode !== "Generate Code" && !isMonitor) {
      const timer = setTimeout(() => {
        const monitorsRef = ref(db, `monitors/${generatedCode}`);
        update(monitorsRef, { currentSport: currentSport });
      }, 10000);

      return () => clearTimeout(timer);
    }
  }, [currentSport, generatedCode]);

  useEffect(() => {
    if (currentSport && sportsSettings?.[currentSport]?.halfLength)
      setHalfLength(sportsSettings?.[currentSport]?.halfLength);
  }, [currentSport]);

  const changeSp = (sport) => {
    let _halfLength = sportsSettings?.[sport].halfLength;
    setCurrentScore([0, 0, 0, 0]);
    setCurrentScoreboard(0);
    setHalfLength(_halfLength);
    setCurrentSport(sport);

    updateFB({
      currentSport: sport,
      currentScore: [0, 0, 0, 0],
      score: [0, 0, 0, 0],
      sport: sport,
      isRugbyLeague: isRugbyLeague,
      halfLength: _halfLength,
    });
  };

  const changeTime = (value) => {
    let splitTime = value.split(/[.:]/);
    let min = parseInt(splitTime[0]) || 0;
    let sec = 0;

    if (splitTime.length > 1) {
      let secsString = splitTime[1].substring(0, 2);
      sec = parseInt(secsString) || 0;
    }

    if (isNaN(min)) {
      min = 0;
    }

    if (isNaN(sec)) {
      sec = 0;
    }

    if (min < 0) {
      min = 0;
    }

    if (sec < 0 || sec > 59) {
      sec = 0;
    }

    setStarted(false);

    // if (!invalidCode) {
    updateFB({
      time: min,
      started: false,
      startTime: new Date().getTime(),
      timeStop: { minutes: min, seconds: sec },
      timeIsRed: false,
    });
    // }
  };

  const addScore = (e, team, multiplier) => {
    e.preventDefault();
    let score = currentScore;

    if (currentSport === "r" && (team == 1 || team === 3)) {
      team--;
    }
    score[team] = parseInt(score[team]) + 1 * multiplier;

    if (score[team] <= 0) score[team] = 0;

    setCurrentScore(() => [...score]);

    if (bcast != null) {
      bcast.postMessage(["score", [...score]]);
    }

    updateFB({
      score: score,
    });
  };

  const changeScoreboard = () => {
    let numScoreboards = sportsSettings?.[currentSport].scoreboards.length - 1;

    const _currentSb = currentScoreboard;
    const nextScoreboard = _currentSb >= numScoreboards ? 0 : _currentSb + 1;

    updateFB({ currentScoreboard: nextScoreboard });
    setCurrentScoreboard(nextScoreboard);
  };

  const changeIsRugbyLeague = () => {
    let notLeague = !isRugbyLeague;
    setIsRugbyLeague(notLeague);

    updateFB({
      isRugbyLeague: notLeague,
    });
  };

  const handleChangeColor = (color, e, offset = [0, 0]) => {
    if (isMonitor) return;
    let mouseX = e.nativeEvent.offsetX + offset[0];
    let mouseY = e.nativeEvent.offsetY + offset[1];

    setShowColorPicker(true);
    setColors((colors) => {
      return { ...colors, currentColor: color };
    });
    setMouse([mouseX, mouseY]);
  };

  const updateTimerState = (startedTime) => {
    updateFB({
      started: started,
      startTime: startedTime,
      timeStop: { minutes, seconds },
    });
  };

  const initiateDB = (code) => {
    if (!isMonitor)
      set(ref(db, "monitors/" + code), {
        teamA,
        teamB,
        score: currentScore,
        currentScoreboard: currentScoreboard,
        colors: colors,
        started: started,
        sport: currentSport,
        halfLength,
        time: time,
        timeInc: -1,
        cardsObj: cardsObj,
        cards: cards,
        isRugbyLeague: isRugbyLeague,
        crest,
        showTotalScore,
        addedTime,
      }).then(() => setEnableFB(true));
  };

  const getControllerInitialValues = async (inputCode) => {
    const codeRef = ref(db, `/customers/${currentUser.uid}/${inputCode}`);
    const codeSnapshot = await get(codeRef);

    console.log("codeSnapshot.val()");
    console.log(codeSnapshot.val());

    if (!codeSnapshot.exists()) {
      setInvalidCode(true);
      return "invalid";
    }

    const { monitor: code } = codeSnapshot.val();

    const userDocRef = ref(db, `monitors/${code}`);

    console.log({ code });

    try {
      const docSnapshot = await get(userDocRef);

      if (!docSnapshot.exists()) {
        setInvalidCode(true);
        return "invalid";
      }

      const val = docSnapshot.val();
      let sport = val?.sport || val?.currentSport;
      if (sport === "t" || sport === "pd") setTennisVariant(val?.tennisVariant);

      if (["f", "s", "r"].includes(sport)) {
        setCurrentSport(sport);
        setGeneratedCode(code);
        // initiateDBListener(code);
      } else if (["t", "d", "p", "bb", "pd"].includes(sport)) {
        setCurrentSport(sport);
        setGeneratedCode(code);
      }
    } catch (error) {
      console.error("Error fetching controller initial values:", error);
    }
  };

  const getControllerInitialValuesMonitor = async (inputCode) => {
    const userDocRef = ref(db, `monitors/${inputCode}`);

    try {
      const docSnapshot = await get(userDocRef);

      if (!docSnapshot.exists()) {
        setInvalidCode(true);
        return "invalid";
      }

      const val = docSnapshot.val();
      let sport = val?.sport || val?.currentSport;
      if (sport === "t" || sport === "pd") setTennisVariant(val?.tennisVariant);

      if (["f", "s", "r"].includes(sport)) {
        setCurrentSport(sport);
        setGeneratedCode(inputCode);
        setEnableFB(true);
        // initiateDBListener(code);
      } else if (["t", "d", "p", "bb", "pd"].includes(sport)) {
        setCurrentSport(sport);
        setGeneratedCode(inputCode);
        setEnableFB(true);
      }
    } catch (error) {
      console.error("Error fetching controller initial values:", error);
    }
  };

  const handleChangeCrest = (inputCrest, key) => {
    let tempCrests = crest;
    tempCrests[key] = inputCrest;
    setCrest(tempCrests);
    updateFB({ crest: tempCrests });
  };

  useEffect(() => {
    // This useEffect will run whenever currentSport or generatedCode changes
    if (currentSport && generatedCode !== "Generate Code") {
      setTimeout(() => setEnableFB(true), 500);
    }
  }, [currentSport, generatedCode]);

  const initiateDBListener = async (genCode) => {
    const code = genCode || generatedCode;
    let dbRef = {};

    try {
      const db = getDatabase();
      let dbRef = ref(db, "monitors/" + "/" + code);

      const unsubscribe = onValue(dbRef, (snapshot) => {
        if (snapshot.exists()) {
          console.log("listener sb context");
          const data = snapshot.val();
          setData(data);
          if (data?.cardsObj?.length > 0) setCardsObj([...data.cardsObj]);
          if (data.cards) setCards([...data.cards]);
          setGeneratedCode(code);
          setEnableListener(true);
          setTeamA(data?.teamA || t("noun:team-a"));
          setTeamB(data?.teamB || t("noun:team-a"));
          if (data?.score) setCurrentScore(data.score);
          else setCurrentScore([0, 0, 0, 0]);
          setStarted(data?.started || false);
          setCurrentSport(data?.sport || "f");
          if (data?.addedTime !== undefined) setAddedTime(data?.addedTime);
          if (data?.showTotalScore !== undefined)
            setShowTotalScore(data?.showTotalScore);
          setInvalidCode(false);
          if (data?.timeIsRed !== undefined) setTimeIsRed(data?.timeIsRed);
          if (data?.timeForward !== undefined)
            setTimeForward(data?.timeForward);

          // setTimeInc(data?.timeInc || 0);
          // setTimeFB(data?.time || 0);
          setEnableFB(true);
          setIsRugbyLeague(data?.isRugbyLeague || false);
          if (data?.crest) setCrest(data?.crest);

          if (isMonitor) {
            setCurrentScoreboard(data?.currentScoreboard);
            setColors(data?.colors);
          }

          if (isMonitor) {
            setCurrentScoreboard(data?.currentScoreboard);
            setColors(data?.colors);
          }

          if (bcast != null) bcast.postMessage(["score", data.score]);
        } else {
          setInvalidCode(true);
        }
      });

      return unsubscribe;
    } catch (error) {
      console.log(error);
    }

    return () => {};
  };

  useEffect(() => {
    let unsubscribe = () => {};
    if (
      enableFB &&
      generatedCode !== "Generate Code" &&
      ["r", "f", "s"].includes(currentSport)
    ) {
      initiateDBListener(generatedCode).then((unsub) => {
        unsubscribe = unsub;
      });
    }

    return () => {
      console.log("unsubscribe");
      if (typeof unsubscribe === "function") unsubscribe();
    };
  }, [enableFB, currentSport, generatedCode]);

  useEffect(() => {
    if (data) {
      setStartTime(data.startTime);
      if (data?.started === true && data?.startTime !== startTime) {
        if (
          data?.timeStop.seconds !== undefined &&
          data?.timeStop.minutes !== undefined
        ) {
          const currentTime = new Date();
          const elapsedTime = Math.abs(
            Math.floor((currentTime - new Date(data.startTime)) / 1000)
          ); // in seconds

          // Calculate minutes and remaining seconds
          const newMinutes = Math.floor(elapsedTime / 60);
          const newSeconds = elapsedTime % 60;

          setSeconds(newSeconds);
          setMinutes(newMinutes);
          if (data?.started !== undefined) {
            setStarted(data.started);
          }
        }
      }
      // if clock is stopped
      else if (data?.started === false && data?.startTime !== startTime) {
        if (data?.started !== undefined) {
          setStarted(data.started);
        }

        setSeconds(data?.timeStop?.seconds);
        setMinutes(data?.timeStop?.minutes);
      }
    }
  }, [data, startTime]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const target = event.target;
      if (
        target.tagName === "INPUT" ||
        target.tagName === "TEXTAREA" ||
        target.isContentEditable
      ) {
        return;
      }
      // Enable space bar when Ctrl + ; is pressed
      if ((event.ctrlKey || event.metaKey) && event.key === ";") {
        setIsSpaceEnabled(true);
      }

      // Trigger handleStop when Space is pressed, and the feature is enabled
      if (isSpaceEnabled && event.code === "Space") {
        event.preventDefault(); // Prevent default space bar behaviour (e.g., scrolling)
        setStarted((prev) => !prev);
      }
    };

    // Add the keydown event listener
    window.addEventListener("keydown", handleKeyDown);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isSpaceEnabled]); // Re-run effect when `isSpaceEnabled` changes

  const value = {
    currentUser,
    teamA,
    setTeamA,
    teamB,
    setTeamB,
    currentSport,
    setCurrentSport,
    currentScore,
    setCurrentScore,
    halfLength,
    setHalfLength,
    sportsInc,
    setSportsInc,
    started,
    setStarted,
    changeSp,
    colors,
    setColors,
    showColorPicker,
    setShowColorPicker,
    mouse,
    handleChangeColor,
    timeIsRed,
    setTimeIsRed,
    time,
    setTime,
    seconds,
    setSeconds,
    minutes,
    setMinutes,
    timeForward,
    setTimeForward,
    addScore,
    cards,
    setCards,
    cardsObj,
    setCardsObj,
    crest,
    setCrest,
    handleChangeCrest,
    generatedCode,
    setGeneratedCode,
    displayCode,
    setDisplayCode,
    mode,
    setMode,
    currentScoreboard,
    changeScoreboard,
    setCurrentScoreboard,
    addedTime,
    setAddedTime,
    showModal,
    setShowModal,
    enableFB,
    setEnableFB,
    initiateDB,
    initiateDBListener,
    enableListener,
    setEnableListener,
    invalidCode,
    setInvalidCode,
    isRugbyLeague,
    setIsRugbyLeague,
    loading,
    setLoading,
    changeIsRugbyLeague,
    isPhoneController,
    setIsPhoneController,
    showTotalScore,
    setShowTotalScore,
    getControllerInitialValues,
    getControllerInitialValuesMonitor,
    tennisVariant,
    setTennisVariant,
    firstHalfCounter,
    setFirstHalfCounter,
    secondHalfCounter,
    setSecondHalfCounter,
    startTime,
    setStartTime,
    updateTimerState,
    updateFB,
    changeTime,
    setData,
    parameter1,
    setParameter1,
    links,
    setLinks,
    modalCrestsState,
    setModalCrestsState,
  };

  return (
    <ScoreboardContext.Provider value={value}>
      {props.children}
    </ScoreboardContext.Provider>
  );
}

// format points GAA
export const formatPoints = (score, position) => {
  if (score[position] < 1) return "00";
  else if (score[position] < 10) return "0" + score[position];
  else return score[position]?.toString();
};

// team 1 = 0, team 2 = 2
export const formatScore = (score, sport, team) => {
  if (!score) score = [0, 0, 0, 0];
  if (sport === "f") return score[team] + "-" + formatPoints(score, team + 1);
  else if (sport === "s" || sport === "r") return score[team]?.toString();
};
ScoreboardProvider.propTypes = {
  children: PropTypes.any,
  subscriptionIncludes: PropTypes.array,
};
